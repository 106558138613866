import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox as MuiCheckbox,
  Divider,
  Switch,
  Typography,
  withStyles,
  Link,
} from '@material-ui/core';
import DataListHeader from './DataListHeader';
import DataListContent from './DataListContent';
import DataListEmpty from './DataListEmpty';
import DataListError from './DataListError';
import DataListLoading from './DataListLoading';
import DataListSummary from './DataListSummary';
import DataListLoadMore from './DataListLoadMore';
import DataListChips from './DataListChips';
import Empty from 'styleguide/Empty';
import { Palette } from '@woundtech/ui-colors';
import Label from 'styleguide/form/Label';

export const isCollectionEmpty = (data: { [id: string]: any }) => {
  return Object.keys(data).filter(key => !!data[key]).length < 1;
};

export type ToggleSwitchProps = {
  label?: string;
  status?: boolean;
  onToggleClick?: () => void;
};

export type Props = {
  canCreate?: boolean;
  component: React.FC<any>;
  data?: { [key: string]: any }[];
  pinnedData?: { [key: string]: any }[];
  error?: string;
  getOptionDisabled?: (item: any) => boolean;
  getOptionId?: (item: any) => string;
  loading?: boolean;
  hideAddIcon?: boolean;
  networkStatus?: number;
  onCreate?: () => void;
  onLoadMore?: () => void;
  onSearch?: (query: string) => void;
  onSelect: (data: any) => void;
  onToggleTune?: () => void;
  onSortingChange?: () => void;
  searchQuery?: string;
  selectedId?: string;
  textCreate?: string;
  title?: string;
  selectedCount?: number;
  total?: number;
  showItemCard?: boolean;
  showSummary?: boolean;
  showEndText?: boolean;
  filterCount?: string;
  onFilterToggle?: () => void;
  filterToggle?: boolean;
  itemProps?: any;
  sortingOptions?: any;
  size?: 'large' | 'medium' | 'small';
  secondaryAction?: () => void;
  secondaryText?: string;
  showLoadMore?: boolean;
  teritiaryAction?: () => void;
  displayMessage?: string;
  onToggleSelectAll?: (data: any) => void;
  showText?: string;
  showMultipleSelect?: boolean;
  onCardCheckBoxToggle?: (data: any) => void;
  selectAllChecked?: boolean;
  noPcpFax?: boolean;
  createButtonLoading?: boolean;
  fullRow?: boolean;
  listControls?: React.ReactElement;
  getTotalCount?: (count: number) => void;
  allRecordsSelected;
  onLinkButtonClick?: () => void;
  showAccordion?: boolean;
  selectedAccordionPanel?: (data: any) => void;
  toggleSwitch?: ToggleSwitchProps;
  heading?: string;
  headingSize?: 'large' | 'medium' | 'small';
  onshowAllRecordsToggle?: () => void;
  continiousScrollToggle: boolean;
  showAllRecordsToggle?: boolean;
  type?: string;
  isEmptyMessageShow?: boolean;
  onFilterEncounter?: (data: any) => void;
  isPrefillSectionVisible?: boolean;
};

const DataList: React.FC<Props> = ({
  component,
  data = [],
  pinnedData,
  error,
  loading,
  networkStatus,
  hideAddIcon,
  onCreate,
  onLoadMore,
  onSearch,
  onSelect,
  onToggleTune,
  toggleSwitch,
  onSortingChange,
  getOptionDisabled = () => false,
  getOptionId = option => option?.id,
  searchQuery,
  selectedId,
  textCreate,
  total,
  canCreate = true,
  createButtonLoading,
  title,
  showItemCard,
  showSummary = true,
  showEndText = true,
  filterCount,
  onFilterToggle,
  filterToggle,
  itemProps,
  sortingOptions,
  size = 'large',
  secondaryAction,
  secondaryText,
  showLoadMore = true,
  teritiaryAction,
  displayMessage,
  onCardCheckBoxToggle,
  onToggleSelectAll,
  showText,
  showMultipleSelect,
  selectAllChecked,
  noPcpFax,
  selectedCount,
  fullRow,
  listControls,
  getTotalCount,
  onLinkButtonClick,
  allRecordsSelected,
  showAccordion,
  selectedAccordionPanel,
  heading,
  headingSize = 'medium',
  onshowAllRecordsToggle,
  continiousScrollToggle = false,
  showAllRecordsToggle,
  type,
  isEmptyMessageShow = true,
  onFilterEncounter,
  isPrefillSectionVisible = false,
}) => {
  const filterDefinitions = useMemo(
    () => [
      {
        id: 'query',
      },
    ],
    []
  );

  const appliedFilters = useMemo(
    () => ({
      query: searchQuery,
    }),
    [searchQuery]
  );

  const results = useMemo(() => {
    return [...(pinnedData || []), ...(data || [])];
  }, [data, pinnedData]);

  const totalCount = useMemo(() => {
    return (total || data?.length) + (pinnedData?.length || 0);
  }, [total, data, pinnedData]);

  const displayedCount = useMemo(() => {
    return data?.length + (pinnedData?.length || 0);
  }, [data, pinnedData]);

  const hasAppliedFilters = !isCollectionEmpty(appliedFilters);
  const hasResults = Array.isArray(results) && results.length > 0;
  const loadMoreLoading = loading && networkStatus === 3;
  const loadAllLoading = loading && networkStatus !== 3;
  const Checkbox = withStyles({
    root: {
      color: Palette.Blue.Dark,
      '&$checked': {
        color: Palette.Blue.Dark,
      },
    },
    checked: {},
  })((props: any) => <MuiCheckbox {...props} />);
  useEffect(() => {
    getTotalCount && getTotalCount(totalCount);
  }, [getTotalCount, totalCount]);

  const onScrollCallback = e => {
    if (continiousScrollToggle) {
      const scrollPosition = Math.round(
        e.target.scrollHeight - e.target.scrollTop
      );
      const scrollPositionDiff = e.target.clientHeight - scrollPosition;
      const bottom =
        scrollPosition === e.target.clientHeight ||
        (scrollPositionDiff <= 2 && scrollPositionDiff >= -2);
      if (bottom) {
        return (
          onLoadMore &&
          !loading &&
          (displayedCount < totalCount ? onLoadMore() : null)
        );
      }
    }
    return null;
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      height={isPrefillSectionVisible ? 'calc(100% - 84px)' : 1}
      width={1}
    >
      <Box>
        <DataListHeader
          fullRow={fullRow}
          title={title}
          size={size}
          data={results}
          onCreate={onCreate}
          onSearch={onSearch}
          hideAddIcon={hideAddIcon}
          onToggleTune={onToggleTune}
          toggleSwitch={toggleSwitch}
          onSortingChange={onSortingChange}
          sortingOptions={sortingOptions}
          searchQuery={searchQuery}
          textCreate={textCreate}
          canCreate={canCreate}
          canSearch={!loading && !hasResults && !searchQuery}
          filterCount={filterCount}
          secondaryAction={secondaryAction}
          secondaryText={secondaryText}
          loading={loading}
          teritiaryAction={teritiaryAction}
          createButtonLoading={createButtonLoading}
          listControls={listControls}
          type={type}
          onFilterEncounter={onFilterEncounter}
        />
        {hasAppliedFilters && (
          <>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <DataListChips
                  definitions={filterDefinitions}
                  values={appliedFilters}
                  onDelete={() => onSearch && onSearch('')}
                  disabled={loading}
                />
              </Box>
              {onFilterToggle && (
                <Box display="flex">
                  <Switch
                    size="small"
                    checked={filterToggle}
                    onChange={onFilterToggle}
                    color="primary"
                    value={filterToggle}
                    disabled={loading}
                  />
                  <Typography>Apply filters</Typography>
                </Box>
              )}
            </Box>
            <Box mb={1}>
              <Divider />
            </Box>
          </>
        )}
      </Box>
      {!loadAllLoading && !error && showText && totalCount > 0 && (
        <Box display="flex" justifyContent="flex-start" pb={1}>
          <Box>
            <Button
              disabled={loading || noPcpFax}
              disableElevation
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onToggleSelectAll}
            >
              <Box ml={-2}>
                <Checkbox
                  color="primary"
                  checked={
                    (selectedCount && selectAllChecked) || allRecordsSelected
                  }
                  disabled={loading || noPcpFax}
                />
                <span>{showText}</span>
              </Box>
            </Button>
          </Box>
          <Box p={2}>
            <Typography noWrap variant="body2">{`Selected ${
              allRecordsSelected ? totalCount : selectedCount
            } of ${totalCount}`}</Typography>
          </Box>
          <Box p={2}>
            <Link
              component="button"
              variant="body2"
              onClick={onLinkButtonClick}
            >
              {allRecordsSelected
                ? 'Clear Selection'
                : `Select all ${totalCount} records`}
            </Link>
          </Box>
        </Box>
      )}

      {heading && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={1}
          height="auto"
          mb={1}
        >
          <Box>
            <Label size={headingSize}>{heading}</Label>
          </Box>
          {onshowAllRecordsToggle && (
            <Box display="flex">
              <Typography>Failed</Typography>
              <Switch
                size="small"
                checked={showAllRecordsToggle}
                onChange={onshowAllRecordsToggle}
                color="primary"
                value={showAllRecordsToggle}
                disabled={loading}
              />
              <Typography>All</Typography>
            </Box>
          )}
        </Box>
      )}

      {displayMessage ? (
        <Box width={1} height="auto">
          <Empty text={displayMessage} />
        </Box>
      ) : (
        <Box
          flexGrow={1}
          overflow="hidden auto"
          display="flex"
          onScroll={onScrollCallback}
        >
          <Box width={1} height="auto">
            {loadAllLoading && <DataListLoading />}
            {error && <DataListError text={error} />}
            {!loadAllLoading && !error && hasResults && (
              <DataListContent
                showItemCard={showItemCard}
                component={component}
                data={results}
                onSelect={onSelect}
                getOptionDisabled={getOptionDisabled}
                getOptionId={getOptionId}
                selectedId={selectedId}
                dataProps={itemProps}
                showCheckbox={showText || showMultipleSelect ? true : false}
                onCardCheckBoxToggle={onCardCheckBoxToggle}
                showMultipleSelect={showMultipleSelect}
                showAccordion={showAccordion}
                selectedAccordionPanel={selectedAccordionPanel}
              />
            )}
            {!loadAllLoading && !error && hasResults && showLoadMore && (
              <DataListLoadMore
                showEndText={showEndText}
                displayedCount={displayedCount}
                loading={loadMoreLoading}
                onLoadMore={onLoadMore}
                totalCount={totalCount}
                continiousScrollToggle={continiousScrollToggle}
              />
            )}
            {!loadAllLoading && !error && !hasResults && isEmptyMessageShow && (
              <DataListEmpty />
            )}
          </Box>
        </Box>
      )}

      {showSummary && hasResults && !loadAllLoading && !error && showLoadMore && (
        <Box mt={1}>
          <DataListSummary count={displayedCount} total={totalCount} />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(DataList);
